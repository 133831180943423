* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100vh;
  width: 100vw;
  height: -webkit-fill-available;
  height: fill-available;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.mapboxgl-canvas-container {
  flex-grow: 1;
}
.mapboxgl-canvas {
  height: 100%;
  min-height: 300px;
}

.mapboxgl-marker {
  z-index: 0;
}

.mapboxgl-marker-top {
  z-index: 1;
}



.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}



rect.recharts-brush-slide, .recharts-brush-traveller {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}
.recharts-brush-traveller {
  opacity: 0.6;
}

.recharts-brush:hover rect.recharts-brush-traveller, .recharts-brush:hover rect.recharts-brush-slide {
  opacity: 1;
}